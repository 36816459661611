import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

const Home = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/Notfound'));
const ImprintDataProtection = lazy(()=> import('./pages/ImprintDataProtection'));


const Loader = () => {
    return <div className="row full-screen align-content-center">
        <div className="col-12 my-auto text-center">
            <div className="spinner-border my-spinner-loader avocado"/>
        </div>
    </div>
}

const routing = (
    <>
        <Router>
            <div>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/impressum&dsgvo/" component={ImprintDataProtection}/>
                        <Route component={NotFound}/>
                    </Switch>
                </Suspense>
            </div>

        </Router>
    </>);

ReactDOM.render(routing, document.getElementById('root'));